@color1: #552322;
@color2: #fcbe45;

// Extralarge devices (desktops, less than 1400px)
@media (max-width: 1399.98px) {
	nav.section-header {
		.container {
			max-width: 100%;
		}

		.header-management {
			.management-logo {
				.logo-image {
					&.image-size-9 {
						min-width: 150px;
						max-width: 150px;
					}
				}
			}
		}
	}
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
	.header-bag-sidebar {
		.sidebar-service {
			width: 620px;
		}
	}

	nav.section-header {
		.header-management {
			padding-right: 24px;

			.management-offer-project {
				display: none;
			}

			.management-service {
				.service-nav-button {
					display: block;
					margin-left: 20px;

					.nav-button {
						background: @color2;
						border-radius: 30px;
						position: relative;
						height: 50px;
						min-width: 50px;
						max-width: 50px;
						margin-right: 0;

						.button-bar {
							width: 20px;
						}

						.button-name {
							display: none;
						}
					}
				}
			}
		}

		&.scrolled {
			.header-management {
				padding-right: 12px;
			}
		}
	}

	section.produkt-section-content {
		.content-inner {
			.product-info {
				.info-add {
					.breakpoint {
						display: none;
					}
				}
			}
		}
	}
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
	.animated-background-wrapper {
		.wrapper-inner {
			display: none;
		}
	}

	section.section-welcome-project {
		.welcome-item {
			.item-description {
				.container {
					max-width: 100%;
				}

				.description-inner {
					h1, h2 {
						font-size: 60px;
					}

					p {
						font-size: 22px;
						max-width: 400px;
					}
				}
			}
		}
	}

	section.section-offer-project {
		.product-service-cta {
			width: 100%;
			padding-top: 35%;

			.cta-inner {
				padding: 40px;

				.btn-project {
					margin-top: 20px;
				}
			}
		}

		.products-flexbox {
			.product-service {
				&:nth-child(4), &:nth-child(5) {
					display: flex;
				}
			}
		}
	}
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
	.header-bag-sidebar {
		width: 100%;

		.sidebar-service {
			width: 100%;
		}
	}

	nav.section-header {
		.header-management {
			.management-search-engine-wrapper {
				padding-left: 20px;

				.heading {
					display: none;
				}
			}
		}

		&.scrolled {
			.header-management {
				.management-search-engine-wrapper {
					padding-left: 10px;
				}
			}
		}
	}

	section.section-categories {
		.categories-flexbox {
			margin-top: 30px;

			.flexbox-service {
				&:nth-child(1) {
					width: 100%;
					padding-top: 40%;
				}

				&:nth-child(2) {
					width: 100%;
					flex-direction: row;

					.service-inner {
						width: 48%;
						padding-top: 40% !important;

						&:nth-child(2) {
							margin-top: 0;
						}
					}
				}

				&:nth-child(3) {
					width: 100%;
					flex-direction: row;

					.service-inner {
						width: 48% !important;
						padding-top: 40% !important;

						&:nth-child(2) {
							margin-top: 0;
						}

						&:nth-child(3) {
							width: 100% !important;
						}
					}
				}
			}
		}
	}

	section.kategoria-section-content {
		.content-description {
			.description-inner {
				p {
					font-size: 14px;
				}
			}
		}
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
	.section-heading {
		h1, h2 {
			font-size: 35px !important;
		}

		p {
			font-size: 16px !important;
		}
	}

	section.section-welcome-project {
		.welcome-item {
			.item-description {
				min-height: 750px;

				.description-inner {
					margin-top: 30px;
					padding: 20px;
					background: rgba(225, 216, 202, 0.75);
					text-align: center;
					border-radius: 20px;

					h1, h2 {
						font-size: 40px;
						color: @color1 !important;
						font-family: 'tt_commons_boldbold';
						text-shadow: none !important;
					}

					p {
						color: #333 !important;
						margin-top: 20px;
						font-size: 18px;
						max-width: 100%;
					}

					.btn-project {
						margin-top: 20px;
						padding: 20px;
					}
				}
			}
		}

		.owl-carousel-welcome {
			.owl-dots {
				top: auto;
				bottom: 90px;
				transform: translateX(-50%);
				left: 50%;
				flex-direction: row;
				align-items: center;
			}
		}
	}

	section.section-offer-project {
		.product-service-cta {
			padding-top: 52%;

			.cta-inner {
				padding: 30px;

				.btn-project {
					margin-top: 10px;
				}
			}
		}

		.products-flexbox {
			.product-service {
				&:nth-child(4), &:nth-child(5) {
					display: flex;
				}
			}
		}
	}

	section.produkt-section-content {
		.content-inner {
			.product-info {
				.info-description {
					p, li {
						font-size: 14px;
					}
				}
			}
		}
	}

	section.kategoria-section-content {
		.content-description {
			border: none;
			background: rgba(255, 255, 255, 0.7);
			padding-bottom: 90px;

			.description-inner {
				h1, h2 {
					color: @color1;
					font-family: 'tt_commons_boldbold';
				}

				p {
					font-size: 18px;
					color: #333;
					font-family: 'tt_commonsmedium';
				}
			}
		}
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 470px) {
	nav.section-header {
		padding-top: 10px;

		.container {
			padding: 0 10px;
		}

		.header-management {
			padding: 12px 15px 12px 13px;

			.management-logo {
				.logo-image {
					&.image-size-9 {
						min-width: 110px;
						max-width: 110px;
					}
				}
			}

			.management-service {
				.service-icons {
					a, button {
						svg {
							height: 19px;
							width: 19px;
						}

						.quantity-badge {
							left: 13px;
						}
					}

					& + .service-icons {
						margin-left: 12px;
					}
				}

				.service-nav-button {
					margin-left: 15px;

					.nav-button {
						height: 35px;
						min-width: 35px;
						max-width: 35px;

						.button-bar {
							width: 15px;

							&:nth-child(2) {
								margin-top: 3px;
							}

							&:nth-child(3) {
								margin-top: 3px;
							}
						}
					}
				}
			}

			.management-search-engine-wrapper {
				padding: 12px 15px 12px 13px;

				.search-engine-inner {
					button {
						svg {
							height: 19px;
							width: 19px;
						}
					}
				}

				.close-button {
					margin-left: 20px;

					svg {
						height: 13px;
						width: 13px;
					}
				}
			}
		}

		&.scrolled {
			.header-management {
				padding: 12px 15px 12px 13px;

				.management-logo {
					.logo-image {
						&.image-size-9 {
							min-width: 110px;
							max-width: 110px;
						}
					}
				}

				.management-search-engine-wrapper {
					padding: 12px 15px 12px 13px;
				}
			}
		}
	}
}