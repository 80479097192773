@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
	background: #e1d8ca;
	font-weight: 500;

	.btn-project {
		padding: 20px 60px;
		border-radius: 40px;
		font-size: 16px;
		font-weight: 700;
		letter-spacing: 1px;
		border: none;
		color: #fff;
		display: inline-block;
		transition: all 0.5s;

		&.btn-yellow {
			background: @color2;
			color: @color1;

			&:hover {
				background: darken(@color2, 10%);
			}
		}

		&.btn-border-brown {
			background: #fff;
			border: 1px solid @color1;
			color: @color1;

			&:hover {
				background: @color1;
				color: #fff;
			}
		}

		&.btn-empty-brown {
			background: none;
			padding: 0 !important;
			color: @color1;

			&:hover {
				color: #000;
			}
		}

		&.btn-icon {
			display: flex;
			align-items: center;
			transition: all 0.4s;

			svg {
				min-width: 15px;
				max-width: 15px;
				transform: translateY(-2px);
				height: 15px;
				fill: @color1;
				margin-right: 10px;
			}

			span {
				white-space: nowrap;
			}
		}

		&.btn-small {
			padding: 12px 30px 11px 30px;
			font-size: 14px;
			letter-spacing: 0;
		}

		&.loading {
			position: relative;
			pointer-events: none;

			&:before {
				content: '';
				position: absolute;
				top: -10%;
				left: -5%;
				width: 110%;
				height: 120%;
				background: #e1d8ca;
				opacity: 0.85;
				filter: blur(1px);
			}

			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background: url(../img/icons/loading.svg) center;
				background-size: cover;
				height: 18px;
				width: 18px;
				animation: loader-rotate cubic-bezier(0.455, 0.03, 0.515, 0.955) 4s infinite;
			}

			@keyframes loader-rotate {
				0% {
					transform: translate(-50%, -50%) rotate(0deg);
				}
				100% {
					transform: translate(-50%, -50%) rotate(360deg);
				}

			}
		}
	}
}

@color1: #552322;
@color2: #fcbe45;

.products-flexbox {
	.product-service {
		.badge-wrapper {
			border-radius: 17px 0 0 0;
			overflow: hidden;

			.badge {
				border-radius: 3px;
			}
		}

		.service-favorite {
			top: 8px;
			right: 8px;
			border-radius: 50%;

			&.checked, &:hover {
				svg {
					path {
						&:nth-child(1) {
							fill: @color1;
						}
					}
				}
			}
		}

		.service-image-wrapper {
			border-radius: 20px;
			background: #fff;
			overflow: hidden;

			.image-cover {
				.cover-inner {
					border-radius: 50%;
				}
			}
		}

		.name {
			font-size: 16px;
			font-weight: 600;
			color: #333;
		}

		.price {
			color: #333;
			font-size: 20px;

			small {
				font-size: 20px;
			}

			span {
				font-size: 13px;
			}
		}
	}
}

.news-flexbox {
	.news-service {
		.service-image-wrapper {
			border-radius: 20px;
			overflow: hidden;

			.image-cover {
				.cover-inner {
					border-radius: 50%;
				}
			}
		}

		.date {
			color: #555;
			text-align: center;
			font-size: 14px;
			letter-spacing: 3px;
		}

		.name {
			padding-right: 0;
			font-size: 19px;
			font-weight: 600;
			color: #333;
			text-align: center;
		}

		&:hover {
			.service-image-wrapper {
				.image-cover {
					opacity: 1;
				}
			}

			.name {
				text-decoration: none !important;
			}
		}
	}
}

.return-to-top {
	border-radius: 50%;
	z-index: 1111;
}

.select-wrapper {
	box-shadow: 3px 3px 12px 0px rgba(0, 0, 0, 0.2);
	border: none;
}

.input-service {
	input, textarea {
		border: none;
		box-shadow: 5px 7px 20px 0px rgba(0, 0, 0, 0.08);
	}

	textarea {
		border-radius: 15px;
	}
}

.checkbox-wrapper {
	.radio-box {
		.box-square {
			margin-top: 1px;
		}
	}
}

.breadcrumb-wrapper {
	padding: 0 0 0 80px;

	.breadcrumb {
		.breadcrumb-item {
			font-size: 12px;
			font-weight: 400;
			color: #999;

			a {
				transition: all 0.4s;
			}
		}

		.breadcrumb-item+.breadcrumb-item::before {
			font-weight: 200;
		}
	}
}

.body-wrapper {
	&.active {
		transform: none;
		filter: none;
	}
}

.header-bag-sidebar {
	.sidebar-service {
		.service-inner {
			.product-service-wrapper {
				.service-image-wrapper {
					background: none;

					.service-image {
						border-radius: 10px;
					}
				}
			}
		}

		.product-modal {
			.modal-inner {
				.service-image-wrapper-outer {
					.service-image-wrapper {
						background: none;
						
						.service-image {
							border-radius: 20px;
						}
					}
				}
			}
		}
	}

	&.active {
		z-index: 3333;
	}
}

.animated-background-wrapper {
	position: relative;
	overflow: hidden;
	z-index: 888;

	&.index {
		margin-top: -80px;
	}

	.wrapper-inner {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		pointer-events: none;

		&.inner-type-1 {
			.element-1 {
				position: absolute;
				top: 5%;
				left: 20%;
				height: 150px;
			}

			.element-2 {
				position: absolute;
				top: 0%;
				right: 2%;
				height: 350px;
			}

			.element-3 {
				position: absolute;
				top: 1000px;
				left: 2%;
				height: 200px;
			}

			.element-4 {
				position: absolute;
				top: 1500px;
				left: 32%;
				height: 150px;
			}

			.element-5 {
				position: absolute;
				top: 0%;
				right: 2%;
				height: 250px;
			}

			.element-6 {
				position: absolute;
				top: 0%;
				right: 2%;
				height: 200px;
			}
		}

		&.inner-type-2 {
			.element-1 {
				position: absolute;
				top: 5%;
				left: 20%;
				height: 150px;
			}

			.element-2 {
				position: absolute;
				top: 0%;
				right: 35%;
				height: 350px;
			}

			.element-3 {
				position: absolute;
				top: 1200px;
				left: 2%;
				height: 200px;
			}

			.element-4 {
				position: absolute;
				top: 1800px;
				right: 0%;
				height: 150px;
			}

			.element-5 {
				position: absolute;
				top: 2500px;
				left: 18%;
				height: 90px;
			}

			.element-6 {
				position: absolute;
				top: 0%;
				right: 35%;
				height: 250px;
			}

			.element-7 {
				position: absolute;
				top: 0%;
				right: 35%;
				height: 200px;
			}
		}
	}

	> * {
		position: relative;
	}
}

nav.section-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 2222;
	padding-top: 20px;
	transition: all 0.5s;

	.header-management {
		background: #fff;
		padding: 15px 40px 15px 23px;
		border-radius: 100px;
		box-shadow: 5px 12px 40px 0px rgba(0, 0, 0, 0.07);
		transition: all 0.5s;

		.management-logo {
			.logo-image {
				transition: all 0.5s;

				img {
					transition: all 0.5s;

					&:nth-child(2) {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
					}
				}
			}
		}

		.management-offer-project {
			transform: translateY(3px);

			> ul {
				display: flex;
				align-items: center;

				> li {
					> a, button {
						padding: 0;
						border: none;
						background: none;
						outline: none;
						font-weight: 600;
						color: @color1;
						font-size: 13px;
						letter-spacing: -0.3px;
						transition: all 0.4s;

						&:hover {
							color: @color2;
						}
					}

					.dropdown {
						> a {
							font-weight: 600;
							color: @color1;
							font-size: 17px;
							transition: all 0.4s;
							position: relative;

							&:after {
								content: '▾';
								margin-left: 10px;
							}

							&:hover, &.show {
								color: @color2;
							}
						}

						.dropdown-menu {
							padding: 10px;
							border-radius: 0;
							top: 35px !important;
							border: none;
							
							.dropdown-item {
								font-family: "tt_commons_boldbold";
								transition: all 0.4s;
								padding: 0;
								padding: 10px;
								color: @color1;

								&:hover {
									color: inherit;
									background: @color2;
									color: @color1;
								}
							}
						}
					}

					& + li {
						margin-left: 16px;
					}
				}
			}
		}

		.management-service {
			.service-icons {
				a, button {
					svg {
						height: 22px;
						width: 22px;
					}

					.quantity-badge {
						background: #9b827b;
						top: -9px;
						left: 17px;
					}

					&:hover {
						svg {
							transform: translateY(-2px);
						}
					}
				}

				&:nth-child(2) {
					margin-left: 0;
				}

				&:nth-child(4) {
					a, button {
						.quantity-badge {
							left: 13px;
						}
					}
				}

				& + .service-icons {
					margin-left: 20px;
				}
			}
		}

		.management-search-engine-wrapper {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: @color2;
			border-radius: 100px;
			padding: 20px 20px 20px 50px;
			display: flex;
			align-items: center;
			transition: all 0.4s;
			transform: scale(1.1);
			opacity: 0;
			visibility: hidden;

			.heading {
				font-weight: 600;
				font-size: 17px;
				white-space: nowrap;
				margin-right: 50px;
				transform: translateY(2px);
			}

			.search-engine-inner {
				background: #fff;
				height: 100%;
				padding: 0 20px;
				border-radius: 50px;
				display: flex;
				align-items: center;
				box-shadow: 2px 8px 20px 0px rgba(0, 0, 0, 0.1);
				width: 100%;

				input {
					background: none;
					display: block;
					width: 100%;
					border: none;
					outline: none;
					padding-top: 5px;
				}

				button {
					border: none;
					background: none;
					padding: 0;
					outline: none;

					svg {
						height: 22px;
						width: 22px;
					}
				}
			}

			.close-button {
				margin-left: 50px;
				border: none;
				background: none;
				padding: 10px;
				outline: none;

				svg {
					height: 15px;
					width: 15px;
				}
			}

			&.active {
				opacity: 1;
				visibility: visible;
				transform: scale(1);
			}
		}
	}

	&.scrolled {
		padding-top: 10px;

		.header-management {
			background: rgba(255, 255, 255, 0.83);
			padding: 10px 27px 10px 15px;

			.management-logo {
				.logo-image {
					img {
						&:nth-child(2) {
							opacity: 0;
						}
					}

					&.image-size-3 {
						min-width: 125px * 0.7;
						max-width: 125px * 0.7;
					}

					&.image-size-6 {
						min-width: 150px * 0.7;
						max-width: 150px * 0.7;
					}

					&.image-size-9 {
						min-width: 175px * 0.7;
						max-width: 175px * 0.7;
					}

					&.image-size-12 {
						min-width: 200px * 0.7;
						max-width: 200px * 0.7;
					}
				}
			}

			.management-offer-project {
				> ul {
					> li {
						.dropdown {
							.dropdown-menu {
								background: rgba(255, 255, 255, 0.83);
								transition: all 0.5s;
								top: 21.52px !important;
							}
						}
					}
				}
			}

			&:hover {
				 background: #fff;

				.management-offer-project {
					> ul {
						> li {
							.dropdown {
								.dropdown-menu {
									background: #fff;
								}
							}
						}
					}
				}
			}
		}

		.management-search-engine-wrapper {
			padding: 10px 20px 10px 50px;
		}
	}
}

section.section-welcome-project {
	overflow: hidden;
	position: relative;

	.welcome-item {
		position: relative;

		.item-background {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			overflow: hidden;

			img {
				object-fit: cover;
				object-position: bottom center;
				height: 100%;
				width: 100%;
			}
		}

		.item-description {
			position: relative;
			min-height: 850px;
			display: flex;
			justify-content: center;
			flex-direction: column;

			.description-inner {
				padding-top: 140px;
				padding-bottom: 100px;	
				padding-left: 68px;

				h1, h2 {
					font-size: 70px;
					font-weight: 600;
					color: @color1;

					span {
						padding-left: 50px;
					}
				}

				p {
					margin-top: 30px;
					font-size: 24px;
					max-width: 500px;
					color: #333;
				}

				.btn-project {
					margin-top: 40px;
				}
			}

			&.white {
				.description-inner {
					h1, h2 {
						color: #fff;
						text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.6);
					}

					p {
						color: #fff;
						text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.6);
					}
				}
			}
		}
	}

	.owl-carousel-welcome {
		width: 101%;
		margin-left: -0.5%;

		.owl-dots {
			position: absolute !important;
			top: 50%;
			transform: translateY(-50%);
			left: 15px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			position: relative;

			.owl-dot {
				margin: 5px;
				height: 19px;
				width: 19px;
				border-radius: 50%;
				background: rgba(255, 255, 255, 0.3);
				display: flex;
				justify-content: center;
				align-items: center;
				border: 1px solid transparent;
				transition: all 0.4s;
				outline: none;

				span {
					background-color: @color1;
					min-width: 6px;
					max-width: 6px;
					height: 6px;
					border-radius: 50%;
					margin: 0;
					transition: all 0.4s;
				}

				&.active {
					border: 1px solid @color1;
					background: #fff;

					span {
						background-color: @color1;
					}
				}
			}
		}
	}

	.welcome-cover {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 222;
		pointer-events: none;
		width: 100%;
	}
}

.section-heading {
	h1, h2 {
		font-size: 50px !important;
		font-weight: 700 !important;
		color: @color1 !important;

		a {
			color: inherit;
		}
	}

	p {
		font-size: 18px !important;
		color: #333;
		font-weight: 500;
	}
}

section.section-offer-project {
	.product-service-cta {
		align-self: flex-start;
		margin-top: 3.5%;
		width: 49%;
		padding-top: 23.5%;
		border-radius: 20px;
		background: #fff;
		position: relative;

		.cta-inner {
			padding: 40px;
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: flex-start;
			flex-direction: column;

			h3 {
				color: #fff;
				width: 50px;
			}

			.btn-project {
				margin-top: 20px;
			}
		}
	}

	.products-flexbox {
		.product-service {
			&:nth-child(4), &:nth-child(5) {
				display: none;
			}
		}
	}
}

section.section-advantages {
	.advantages-flexbox {
		.flexbox-service {
			.service-icon {
				height: 50px;
				width: 50px;
			}

			.service-name {
				font-size: 15px;
				color: #333;
				font-weight: 600;
			}
		}
	}
}

section.section-categories {
	margin-top: 100px;

	.categories-flexbox {
		margin-top: 50px;

		.flexbox-service {
			.service-inner {
				border-radius: 20px;

				.inner-name {
					padding: 10px;

					.name-box {
						border-radius: 3px 3px 17px 17px;
						background: @color2;
						color: @color1;
						font-size: 18px;
						font-weight: 700;

						&.christmas {
							background-color: #5b813e;
							color: #fff;
						}

						&.valentines {
							background-color: #df5e63;
							color: #fff;
						}
					}
				}

				&:hover {
					.inner-name {
						bottom: 0;
					}
				}
			}
		}
	}
}

section.section-newsletter {
	background: rgba(255, 255, 255, 0.7);
}

section.section-blog {
	background: none;

	&.subpage {
		padding-bottom: 0;
		background: none;
	}
}

section.static-page-section-content {
	background: none;
	padding-bottom: 0;

	.content-inner {
		.heading {
			letter-spacing: 0;
			color: @color1;
			font-weight: 600;
		}

		.inner-main-image {
			border-radius: 20px;
		}

		.inner-gallery {
			.gallery-service {
				border-radius: 15px;
			}
		}

		.inner-description {
			h1, h2, h3, h4, h5, h6 {
				color: @color1;
				font-weight: 700;
			}
		}
	}
}

section.kategoria-section-content {
	background: none;
	padding-bottom: 0;

	.content-flexbox {
		.flexbox-body {
			.heading {
				.heading-backlink {
					span {
						transform: translateY(2px);
					}
				}

				.heading-name {
					color: @color1;
					font-weight: 700;
				}
			}

			.body-heading {
				.inner-wrapper {
					.heading-grid-wrapper {
						.heading-grid {
							background: #fff;
							box-shadow: 3px 3px 12px 0px rgba(0, 0, 0, 0.2);
							border: none;
						}
					}
				}
			}
		}
	}

	.content-description {
		border: none;
		background: rgba(255, 255, 255, 0.7);
		padding-bottom: 90px;

		.description-inner {
			h1, h2 {
				color: @color1;
				font-weight: 700;
			}

			p {
				font-size: 18px;
				color: #333;
				font-weight: 500;
			}
		}
	}
}

section.produkt-section-content {
	background: none;

	.heading {
		.heading-backlink {
			margin-bottom: 15px;

			span {
				transform: translateY(2px);
			}
		}
	}

	.content-inner {
		.product-gallery {
			.lSSlideWrapper {
				border-radius: 30px;
			}

			.lSAction {
				.lSPrev {
					left: 10px;
				}

				.lSNext {
					right: 10px;
				}
			}

			.lSGallery {
				li {
					opacity: 0.4;

					a {
						border-radius: 20px;
						overflow: hidden;
					}
				}
			}
		}

		.product-info {
			.info-alert {
				border-radius: 20px;
			}

			h1 {
				font-size: 40px;
				font-weight: 700;
				color: @color1;
			}

			.info-features {
				.feature-inner {
					p {
						&:nth-child(2) {
							color: @color1;
							font-weight: 700;
						}
					}
				}
			}

			.info-price {
				.price-availability {
					border-radius: 20px;
					padding: 18px 15px 15px 20px;
				}
			}

			.info-add {
				margin-top: 0;

				.service-quantity {
					margin-top: 30px;
					margin-right: 30px;

					.input-number-decrement,
					.input-number-increment {
						border-radius: 30px;
						background: #fff;
						color: @color1;

						&:active {
							background: @color2;
							color: @color1;
						}
					}
				}

				.btn-project {
					margin-top: 30px;
					margin-right: 30px;
				}

				.btn-empty {
					margin-top: 30px;
					margin-left: 0 !important;

					&.checked, &:hover {
						svg {
							path {
								&:nth-child(1) {
									fill: @color1;
								}
							}
						}
					}
				}
			}

			.info-codes {
				display: none;
				border-top: 1px solid rgba(255, 255, 255, 0.6);

				.code-inner {
					p {
						&:nth-child(2) {
							color: @color1;
							font-weight: 700;
						}
					}
				}
			}

			.info-description {
				border-top: 1px solid rgba(255, 255, 255, 0.6);

				.description-heading {
					color: @color1;
					font-weight: 700;
				}

				p, li {
					font-size: 18px;
					color: #333;
					font-weight: 500;
				}
			}

			.info-download {
				border-top: 1px solid rgba(255, 255, 255, 0.6);

				.download-heading {
					color: @color1;
					font-weight: 700;
				}
			}
		}
	}
}

.category-nav-wrapper {
	.category-nav {
		li {
			a {
				font-weight: 600;
				font-size: 19px;
				color: #333;
				letter-spacing: 0;
			}

			&.active {
				> a {
					font-weight: 800;
				}
			}

			ul {
				li {
					a {
						color: #333;
					}
				}
			}
		}
	}

	.filter-nav {
		.checkbox-wrapper {
			.radio-box {
				.box-square {
					margin-top: 5px;
				}
			}
		}

		.buttons-wrapper {
			.btn-filter {
				border-radius: 20px;
				padding: 3px 10px 2px 10px;
			}
		}
	}
}

section.kontakt-section-content {
	background: none;
	padding-bottom: 0;

	.content-inner {
		.inner-contacts {
			h1 {
				color: @color1;
				font-weight: 600;
			}
		}

		.inner-company-data {
			border-radius: 20px;
		}
	}

	.content-hours {
		.heading {
			color: @color1;
			font-weight: 600;
		}

		.hours-inner {
			.inner-service {
				border-radius: 10px;
			}
		}
	}

	.content-map-form-wrapper {
		.content-form {
			.heading {
				color: @color1;
				font-weight: 600;
			}
		}
	}

	.content-map {
		.map-inner {
			iframe {
				border-radius: 20px;
			}
		}
	}

	.content-others {
		.heading {
			color: @color1;
			font-weight: 600;
		}

		.others-inner {
			.inner-service {
				background: #fff;
				border: none;
				border-radius: 15px;
				box-shadow: 5px 7px 20px 0px rgba(0, 0, 0, 0.08);
			}
		}
	}
}

section.section-footer {
	padding-top: 50px;
	background: linear-gradient(180deg, rgba(225,216,202,1) 0%, rgba(204,191,171,1) 100%);

	&.active {
		transform: none;
		filter: none;
	}
}